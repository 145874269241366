import request from '@/utils/request'

// 创建订单
export const createOrder = data => {
  return request({
    url: '/goods_order/create_order',
    method: 'post',
    data
  })
}

// 查询订单
export const getOrderInfo = data => {
  return request({
    url: '/goods_order/query',
    method: 'post',
    data
  })
}

// 订单列表
export const getOrderList = params => {
  return request({
    url: '/goods_order/order_lst',
    params
  })
}

// 取消订单
export const cancelOder = data => {
  return request({
    url: '/goods_order/cancel_order',
    method: 'post',
    data
  })
}

// 确认订单
export const confirm = data => {
  return request({
    url: '/goods_order/confirm',
    method: 'post',
    data
  })
}

// 订单详情
export const detail = data => {
  return request({
    url: '/goods_order/detail',
    method: 'get',
    data
  })
}

// 上一次订单详情
export const preOrderInfo = () => {
  return request({
    url: '/goods_order/pre_order_info',
    method: 'get'
  })
}